import React from "react"
import { graphql } from 'gatsby';
import Layout from "../components/Layout"
import Img from "gatsby-image";
import Card from "../components/Card"

export const goats = graphql`
query {
  goat: file(relativePath: { eq: "goat.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  ipadGoat: file(relativePath: { eq: "ipadGoat.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  mobileGoat: file(relativePath: { eq: "mobileGoat.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 700) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;

export default props => {
  const { goat, ipadGoat, mobileGoat } = props.data;
  const sources = [
    {
      ...mobileGoat.childImageSharp.fluid,
      media: "(max-width: 700px)",
    },
    {
      ...ipadGoat.childImageSharp.fluid,
      media: "(min-width: 701px) and (max-width: 1000px)",
    },
    {
      ...goat.childImageSharp.fluid,
      media: "(min-width: 1001px)",
    },
  ];
  
  return (
    <Layout>
      <Card />
      <Img className="h-full z-0"
      alt="Goat"
      fluid={sources} />
    </Layout>
  );
};
