import React from 'react';
import moment from 'moment';

class Countdown extends React.Component {
    state = {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;
            const then = moment(timeTillDate, timeFormat);
            const now = moment();
            const countdown = moment(then - now);
            const days = countdown.format('D');
            const hours = countdown.format('HH');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss');

            this.setState({ days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;

        return (
            <div className="inline-grid grid-cols-4 gap-2 place-items-center">
                <div className=" p-1">
                    {days}
                </div>
                <div className=" p-1">
                    {hours}
                </div>
                <div className=" p-1">
                    {minutes}
                </div>
                <div className=" p-1 text-red-700">
                    {seconds}
                </div>
                <div className="text-xs">
                    days
                </div>
                <div className="text-xs">
                    hours
                </div>
                <div className="text-xs">
                    minutes
                </div>
                <div className="text-xs">
                    seconds
                </div>
            </div>
        );
    }
}

export default Countdown;