import React from 'react'
import Countdown from "./Countdown"


const Card = () => {
    return (
        <div className="absolute z-10 w-full max-h-1/4 uppercase md:w-6/12 md:m-50 md:grid md:place-items-center">
            <div className="border m-2 bg-white rounded-lg grid grid-rows-3 place-items-center p-4">
                <h1 className="font-bold text-2xl">Goat Jazz Incoming:</h1>
                <div className="grid place-items-center mb-4">
                   <p className="">12PM Sunday October 25, 2020</p>
                   <a href="https://goo.gl/maps/JmodumQ3wg76mkGU9" className="underline"><span role="img">📍</span> Wyman Park Dell</a> 
                </div>
                
                <div className="-mt-4">
                   <Countdown timeTillDate="10 25 2020, 12:00 pm" timeFormat="MM DD YYYY, h:mm a"/> 
                </div>
                
            </div>
            
        </div>
    )
}

export default Card;